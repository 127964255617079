import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
  Form,
  UncontrolledAlert,
  Label,
  Button,
} from "reactstrap"

import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { Link, useNavigate, useParams } from "react-router-dom"
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation"
import Select from "react-select"
import Dropzone from "react-dropzone"


const AddNewMenu = props => {

  const {id} = useParams()

  const [alertVisible, setAlertVisible] = useState(false)
 
  const [dish_name, setDishName] = useState("")
  const [dish_description, setDishDescription] = useState("")
  const [serving_size, setServingSize] = useState(0)
  const [price, setPrice] = useState(0)


  const [restaurant_dish_category_id, setDishCategoryId] = useState()

  const [dishCategories, setDishCategories] = useState([])

  const [restaurant_dish_cuisine_id, setDishCuisineId] = useState()
  const [dishCuisine, setDishCuisines] = useState([])


  const token = localStorage.getItem("token")

  const [status, setActive] = useState(true)
  const [sort_order, setSort] = useState(0)

  const [validationErrors, setValidationErrors] = useState({});

  document.title = "PFP Admin - Add Restaurant Menu"

  const breadcrumbItems = [
    { title: "Restaurant", link: "#" },
    { title: "Add New Menu", link: "#" },
    // { title: "Form Elements", link: "#" },
  ]
    // Validation for Select Fields
    const validateForm = () => {
      let errors = {};
      if (!restaurant_dish_category_id) {
        errors.dish_category = "Dish category is required";
      }
      if (!restaurant_dish_cuisine_id) {
        errors.cuisine = "Cuisine is required";
      }
 
      setValidationErrors(errors);
      return Object.keys(errors).length === 0; // Returns true if there are no errors
    };
  useEffect(() => {
    props.setBreadcrumbItems("Add New Menu", breadcrumbItems)
  })
  const navigate = useNavigate()

  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)

  useEffect(()=>{

    const fetchAllDishCategories = async () => {

      try{
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/restaurants/dishes/category/list`,{
            headers: {
              "Authorization": `Bearer ${token}`,
            }
          }
        )
        const json = await response.json()
  
        if (response.ok) {
         const options = json.data.map((cat)=>({
          label: cat.category_name,
          value: cat.id
         }))
         setDishCategories(options || [])
         
        }
      }catch(error){

      }

    }
    const fetchAllDishCuisines = async () => {

      try{
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/restaurant/dish/cusines/list`,{
            headers: {
              "Authorization": `Bearer ${token}`,
            }
          }
        )
        const json = await response.json()
  
        if (response.ok) {
         const options = json.data.map((cui)=>({
          label: cui.cuisine_name,
          value: cui.id
         }))
         setDishCuisines(options || [])
         
        }
      }catch(error){

      }

    }

    fetchAllDishCategories()
    fetchAllDishCuisines()
  },[])
  



    const handleSubmit = async (e) => {
      e.preventDefault();

      await validateForm()
  
      let active = status === true ? 1 : 0;



      const restaurant_id = id


      const formData = new FormData();

      formData.append("restaurant_id", restaurant_id);
      formData.append("restaurant_dish_category_id", restaurant_dish_category_id);
      formData.append("restaurant_dish_cuisine_id", restaurant_dish_cuisine_id);
      formData.append("dish_name", dish_name);
      formData.append("dish_description", dish_description);
      formData.append("serving_size", serving_size);
      formData.append("price", price);
      formData.append("sort_order", sort_order);
      formData.append("active", active);

  
      try {
   
        const response = await fetch(

          `${process.env.REACT_APP_API_URL}/api/backend/restaurants/menu/add`,
          {
            method: "POST",
            body: formData,
            headers: {
              "Authorization": `Bearer ${token}`,
            }
       
          }
        );
        if (response.ok) {
          navigate(`/manage-menu/${id}`)
          setAlertVisible(true);
          setTimeout(() => setAlertVisible(false), 5000);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

  return (
    <React.Fragment>
      {alertVisible && ( // Conditionally render the alert
        <UncontrolledAlert color="success">
          <strong>Well done!</strong> You successfully added a new restaurant.
        </UncontrolledAlert>
      )}
      {/* form */}
      <Row className="">
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title">Add New Menu</h4>

              <AvForm onSubmit={handleSubmit} className="needs-validation mt-4">
                <Row>
             
                  <Col md="4">
                    <AvGroup className="mb-3">
                      <Label htmlFor="validationCustom02">Choose Dish Category</Label>
                      <Select
                        // value={restaurant_category_id}
                        onChange={(e)=>setDishCategoryId(e.value)}
                        options={dishCategories}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                      />
                        {validationErrors.dish_category && (
                        <div className="invalid-feedback d-block">
                          {validationErrors.dish_category}
                        </div>
                      )}
                    </AvGroup>
              
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Choose Cuisine</Label>
                      <Select
                        // value={restaurant_category_id}
                        onChange={(e)=>setDishCuisineId(e.value)}
                        options={dishCuisine}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                      />
                       {validationErrors.cuisine && (
                        <div className="invalid-feedback d-block">
                          {validationErrors.cuisine}
                        </div>
                      )}
                    </div>
              
                  </Col>
               
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Dish Name</Label>
                      <AvField
                        name="dish_name"
                        placeholder="Enter Dish Name"
                        onChange={(e)=>setDishName(e.target.value)}
                        type="text"
                        errorMessage="Enter Valid Dish Name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        
                      />
                    </div>
                  </Col>
                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Dish Description</Label>
                      <AvField
                        name="dish_description"
                        placeholder="Dish Description"
                        onChange={(e)=>setDishDescription(e.target.value)}
                        type="textarea"
                        rows={5}
                        errorMessage="Enter Valid Dish Description"
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom01"
                        
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Serving Size</Label>
                      <AvField
                        name="serving_size"
                        placeholder="Serving Size"
                        onChange={(e)=>setServingSize(e.target.value)}
                        type="number"
                      
                        // errorMessage="Enter Valid Serving Size"
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom01"
                        
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Price</Label>
                      <AvField
                        name="price"
                        placeholder="Enter Price"
                        onChange={(e)=>setPrice(e.target.value)}
                        type="number"
                        
                        // errorMessage="Enter Valid Price"
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom01"
                        
                      />
                    </div>
                  </Col>
                
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom04">Sort Order</Label>
                      <AvField
                        name="sort number"
                        placeholder="Enter Sort Number"
                        type="number"
                        
                        errorMessage="Please provide a valid number."
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom04"
                        onChange={(e)=>setSort(e.target.value)}
                        min="0"
                      />
                    </div>
                  </Col>
              
                </Row>
              
              
         
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="invalidCheck"
                    defaultChecked={status}
                    onChange={(e)=>setActive(e.target.checked)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck">
                    Active
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
             
              
                <Button color="primary" type="submit">
                    Add Menu
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* end form */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AddNewMenu)
