import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
  Form,
  UncontrolledAlert,
} from "reactstrap"

import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { Link, useNavigate, useParams } from "react-router-dom"
import Dropzone from "react-dropzone"

const EditNewCategory = props => {
  const { id } = useParams()

  const [category_name, setName] = useState("")
  const [category_description, setDescription] = useState("")
  const token = localStorage.getItem("token")

  const [status, setActive] = useState()

  const [alertVisible, setAlertVisible] = useState(false)
  const [previousFileURL, setPreviousFileURL] = useState("")
  const [error, setError] = useState("")
  const [category_slug, setSlug] = useState("");

  const navigate = useNavigate()

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/restaurants/category/${id}`,{
            headers: {
              "Authorization": `Bearer ${token}`,
            }
          }
        )
        const json = await response.json()
        if (response.ok) {
          setName(json.data.category_name || "")
          setDescription(json.data.category_description || "")
          setActive(json.data.active === 1)
          setPreviousFileURL(json.data.category_image_url)
          setSlug(json.data.category_slug || "")
        } else {
          console.error("Failed to fetch category data:", json.message)
        }
      } catch (error) {
        console.error("Error fetching category data:", error)
      }
    }
    fetchCategory()
  }, [id])

     // Function to fetch slug from the API
     const fetchSlug = async (name) => {
      try {
        const params = new URLSearchParams({
          slug_string: name
        })
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/backend/slug/generate?${params}`, {
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        });
        const json = await response.json();
   
        if (response.ok) {
          setSlug(json.data.slug); 
          console.log(json.data.slug)
        } else {
          console.error("Failed to generate slug");
        }
      } catch (error) {
        console.error("Error fetching slug:", error);
      }
    };
  
  
     // Handle when the input field loses focus (onBlur event)
     const handleBlur = () => {
      if (category_name) {
        fetchSlug(category_name);
      }
    };
  
  const handleSubmit = async e => {
    e.preventDefault()

    let active = 0
    if (status === true) {
      active = 1
    } else if (status === false) {
      active = 0
    }

    const formData = new FormData()

    if (selectedFiles.length === 0) {
      formData.append("category_name", category_name)
      formData.append("category_slug", category_slug)
      formData.append("category_description", category_description)
      formData.append("active", active)
    } else {
      formData.append("category_name", category_name)
      formData.append("category_slug", category_slug)
      formData.append("category_description", category_description)
      formData.append("active", active)
      formData.append("image", selectedFiles[0])
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/restaurants/category/update/${id}`,
        {
          method: "POST",
          body: formData,
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        },
      )

      const json = await response.json()

      if (response.ok) {
        navigate("/all-restaurant-categories")
        // setAlertVisible(true)
        // setTimeout(() => setAlertVisible(false), 5000)
        // setName(json.data.category_name || "")
        // setDescription(json.data.category_description || "")
        // setActive(json.data.active === 1 ? true : false)
      }else{
        setError(json.message)
      }
    } catch (error) {
    } finally {
    }
  }

  document.title = "PFP Admin - Edit Category"

  const breadcrumbItems = [
    { title: "Restaurant", link: "#" },
    { title: "Edit Category", link: "#" },
    // { title: "Form Elements", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Edit Category", breadcrumbItems)
  })

  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)
  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }


  return (
    <React.Fragment>
      {alertVisible && ( // Conditionally render the alert
        <UncontrolledAlert color="success">
          <strong>Well done!</strong> Category updated successfully.
        </UncontrolledAlert>
      )}
      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">Edit Restaurant Category</h4>

              <Row className="">
                <Col lg={6} className="">
                  <div className="mt-5 mt-lg-4">
                    {/* <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Horizontal form</h5> */}

                    <form onSubmit={handleSubmit}>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-3 col-form-label"
                        >
                          Name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            value={category_name}
                            onChange={e => setName(e.target.value)}
                            className="form-control"
                            id="horizontal-firstname-input"
                            placeholder="Enter category name"
                            required
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-3 col-form-label"
                        >
                          Slug
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            value={category_slug}
                            onChange={e => setSlug(e.target.value)}
                            className="form-control"
                            id="horizontal-firstname-input"
                            placeholder="Enter category slug"
                            required
                          />
                            {error.trim() !== "" && (
                        <div className="invalid-feedback d-block">
                          {error}
                        </div>
                      )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-email-input"
                          className="col-sm-3 col-form-label"
                        >
                          Description
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            type="text"
                            value={category_description}
                            onChange={e => setDescription(e.target.value)}
                            rows={4}
                            className="form-control"
                            id="horizontal-email-input"
                            placeholder="Describe the category"
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-email-input"
                          className="col-sm-3 col-form-label"
                        >
                          Image
                        </label>
                        <div className="col-sm-9">
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(acceptedFiles)
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone dz-clickable">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="mdi mdi-cloud-upload-outline text-muted display-4"></i>
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {selectedFiles.length > 0 ? (
                              <>
                                {selectedFiles.map((f, i) => {
                                  return (
                                    <Card
                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      key={i + "-file"}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={f.name}
                                              src={f.preview}
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {f.name}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>{f.formattedSize}</strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )
                                })}
                              </>
                            ) : (
                              <>
                                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <img
                                          data-dz-thumbnail=""
                                          height="80"
                                          className="avatar-sm rounded bg-light"
                                          // alt={f.name}
                                          src={previousFileURL}
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {category_name}
                                        </Link>
                                        <p className="mb-0">
                                          {/* <strong>{f.formattedSize}</strong> */}
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              </>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="row justify-content-end">
                        <div className="col-sm-9">
                          <div className="form-check mb-4">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              checked={status}
                              // defaultChecked={status}
                          
                              id="flexCheckIndeterminate"
                              onClick={
                                
                                (e) =>  {setActive(!status); console.log(!status)}

                                
                              }
                              // onChange={handleChnageActive}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="horizontal-customCheck"
                            >
                              Active
                            </label>
                          </div>

                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* end form */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(EditNewCategory)
