import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
  Form,
  UncontrolledAlert,
  Label,
  Button,
} from "reactstrap"

import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { Link, useNavigate, useParams } from "react-router-dom"
import { AvField, AvForm, AvGroup } from "availity-reactstrap-validation"
import Select from "react-select"
import Dropzone from "react-dropzone"

const AddNewDiscount = props => {
  const { id } = useParams()

  const [alertVisible, setAlertVisible] = useState(false)

  const [bank_id, setBankId] = useState()

  const [banks, setBanks] = useState([])

  const [terms_and_conditions, setTerms] = useState("")

  const [bank_card_id, setCardId] = useState()
  const [cards, setCards] = useState([])

  const [discount_percentage, setDiscount] = useState(0)

  const [validationErrors, setValidationErrors] = useState({})
  const token = localStorage.getItem("token")

  document.title = "PFP Admin - Add Restaurant Discount"

  const breadcrumbItems = [
    { title: "Restaurant", link: "#" },
    { title: "Add New Discount", link: "#" },
    // { title: "Form Elements", link: "#" },
  ]
  // Validation for Select Fields
  const validateForm = () => {
    let errors = {}
    if (!bank_id) {
      errors.bank_id = "Bank is required"
    }
    if (!bank_card_id) {
      errors.bank_card_id = "Card is required"
    }

    setValidationErrors(errors)
    return Object.keys(errors).length === 0 // Returns true if there are no errors
  }
  useEffect(() => {
    props.setBreadcrumbItems("Add New Restaurant Discount", breadcrumbItems)
  })
  const navigate = useNavigate()

  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)

  useEffect(() => {
    const fetchAllBanks = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/banks/list`,{
            headers: {
              "Authorization": `Bearer ${token}`,
            }
          }
        )
        const json = await response.json()

        if (response.ok) {
          const options = json.data.map(bank => ({
            label: bank.bank_name,
            value: bank.id,
          }))
          setBanks(options || [])
        }
      } catch (error) {}
    }

    fetchAllBanks()
  }, [id])
  useEffect(() => {
    console.log("useeeffect")
    const fetchAllBankCards = async () => {
      console.log("function")
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/bank/cards/list/${bank_id}`,{
            headers: {
              "Authorization": `Bearer ${token}`,
            }
          }
        )
        const json = await response.json()

        if (response.ok) {
          const options = json.data.map(bank => ({
            label: `${bank.card_type.toUpperCase()} - ${bank.card_name}`,
            value: bank.id,
          }))
          setCards(options || [])
        }
      } catch (error) {}
    }

    if (bank_id) {
      fetchAllBankCards()
    }
  }, [bank_id])

  const handleSubmit = async e => {
    e.preventDefault()

    await validateForm()

    const restaurant_id = id

    const disc = {
      restaurant_id,
      bank_id,
      bank_card_id,
      terms_and_conditions,
      discount_percentage,
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/restaurant/bank/card/discounts/add`,
        {
          method: "POST",
          body: JSON.stringify(disc),
          headers: {
            "Content-Type": "application/json",
            
              "Authorization": `Bearer ${token}`,
            
          },
        },
      )
      if (response.ok) {
        navigate(`/manage-discounts/${id}`)
        setAlertVisible(true)
        setTimeout(() => setAlertVisible(false), 5000)
      }
    } catch (error) {
      console.error("Error:", error)
    }
  }

  return (
    <React.Fragment>
      {alertVisible && ( // Conditionally render the alert
        <UncontrolledAlert color="success">
          <strong>Well done!</strong> You successfully added a new restaurant.
        </UncontrolledAlert>
      )}
      {/* form */}
      <Row className="">
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title">Add New Discount</h4>

              <AvForm onSubmit={handleSubmit} className="needs-validation mt-4">
                <Row>
                  <Col md="4">
                    <AvGroup className="mb-3">
                      <Label htmlFor="validationCustom02">Choose Bank</Label>
                      <Select
                        // value={restaurant_category_id}
                        onChange={e => setBankId(e.value)}
                        options={banks}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                      />
                      {validationErrors.bank_id && (
                        <div className="invalid-feedback d-block">
                          {validationErrors.bank_id}
                        </div>
                      )}
                    </AvGroup>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Choose Card</Label>
                      <Select
                        // value={restaurant_category_id}
                        onChange={e => setCardId(e.value)}
                        options={cards}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                      />
                      {validationErrors.bank_card_id && (
                        <div className="invalid-feedback d-block">
                          {validationErrors.bank_card_id}
                        </div>
                      )}
                    </div>
                  </Col>

                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">
                        Terms And Conditions
                      </Label>
                      <AvField
                        name="terms"
                        placeholder="Enter Terms And Conditions"
                        onChange={e => setTerms(e.target.value)}
                        type="textarea"
                        rows={5}
                        // errorMessage="Enter Valid Dish Description"
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom01"
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Discount %</Label>
                      <AvField
                        name="discount"
                        placeholder="Enter Discount percentage in number"
                        onChange={e => setDiscount(e.target.value)}
                        type="number"
                        errorMessage="Enter Valid Number"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        min="0"
                        max="100"
                      />
                    </div>
                  </Col>
                </Row>

                <Button color="primary" type="submit">
                  Add Discount
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* end form */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AddNewDiscount)
