import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
  Form,
  UncontrolledAlert,
  Label,
  Button,
} from "reactstrap"

import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { Link, useNavigate, useParams } from "react-router-dom"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import Dropzone from "react-dropzone"

const EditNewRestaurant = props => {
  const { id, type } = useParams()
  const [restaurant_name, setName] = useState("")
  const [restaurant_description, setDescription] = useState("")
  const [premium, setPremium] = useState()
  const [featured, setFeatured] = useState()
  const [beverages, setBeverages] = useState()
  const [breakfast, setBreakFast] = useState()
  const [lunch, setLunch] = useState()
  const [dinner, setDinner] = useState()
  const [snack, setSnack] = useState()
  const [dessert, setDesert] = useState()
  const [keywords, setKeywords] = useState("")
  const [no_of_branches, setBranches] = useState(0)
  const [youtube_url, setYoutube] = useState("")
  const [timing, setTiming] = useState("")
  const [phone_number, setPhoneNumber] = useState("")
  const [status, setActive] = useState()
  const [alertVisible, setAlertVisible] = useState(false)
  const [restaurant_category_id, setRestaurantCategoryId] = useState()
  const [categories, setCategories] = useState([])
  const [oldImages, setOldImages] = useState([])
  const [previousBannerURL, setPreviousBannerURL] = useState("")
  const [error, setError] = useState("")
  const [restaurant_slug, setSlug] = useState("");
  const [selectedFiles, setselectedFiles] = useState([])
  const [bannerFile, setBannerFile] = useState([])
  const token = localStorage.getItem("token")

  useEffect(() => {
    const fetchRestaurant = async () => {
 
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/restaurants/${id}`,{
            headers: {
              "Authorization": `Bearer ${token}`,
            }
          }
        )
        const json = await response.json()
        if (response.ok) {
          setName(json.data.restaurant_name || "")
          setKeywords(json.data.keywords || "")
          setSlug(json.data.restaurant_slug || "")
          setDescription(json.data.restaurant_description || "")
          setBranches(json.data.no_of_branches)
          setTiming(json.data.timing)
          setPhoneNumber(json.data.phone_number)
          setRestaurantCategoryId(json.data.restaurant_category_id)

          setActive(json.data.active === 1 ? true : false)
          setPremium(json.data.is_premium === 1 ? true : false)
          setFeatured(json.data.is_featured === 1 ? true : false)
          setBreakFast(json.data.has_breakfast === 1 ? true : false)
          setLunch(json.data.has_lunch === 1 ? true : false)
          setDinner(json.data.has_dinner === 1 ? true : false)
          setSnack(json.data.has_snack === 1 ? true : false)
          setDesert(json.data.has_dessert === 1 ? true : false)
          setBeverages(json.data.has_beverages === 1 ? true : false)
          setPreviousBannerURL(json.data.local_banner_image_small)
          setOldImages(json.data.files)
          // setPreviousFileURL(json.data.category_image_url)
        } else {
          console.error("Failed to fetch category data:", json.message)
        }
      } catch (error) {
        console.error("Error fetching category data:", error)
      }
    }
    fetchRestaurant()
  }, [id])
  // Function to fetch slug from the API
  const fetchSlug = async (name) => {
    try {
      const params = new URLSearchParams({
        slug_string: name
      })
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/backend/slug/generate?${params}`,{
        headers: {
          "Authorization": `Bearer ${token}`,
        }
      });
      const json = await response.json();
 
      if (response.ok) {
        setSlug(json.data.slug); 
        console.log(json.data.slug)
      } else {
        console.error("Failed to generate slug");
      }
    } catch (error) {
      console.error("Error fetching slug:", error);
    }
  };


   // Handle when the input field loses focus (onBlur event)
   const handleBlur = () => {
    if (restaurant_name) {
      fetchSlug(restaurant_name);
    }
  };
  document.title = "PFP Admin - Edit Restaurant"

  const breadcrumbItems = [
    { title: "Restaurant", link: "#" },
    { title: "Edit Restaurant", link: "#" },
    // { title: "Form Elements", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Edit Restaurant", breadcrumbItems)
  })
  const navigate = useNavigate()

  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)

  useEffect(() => {
    const fetchAllCategories = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/restaurants/category/list`, {
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )
      const json = await response.json()

      if (response.ok) {
        const options = json.data.map(cat => ({
          label: cat.category_name,
          value: cat.id,
        }))
        setCategories(options || [])
      }
    }

    fetchAllCategories()
  }, [])



  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    )
    setselectedFiles(files)
  }

  function handleBannerFiles(files) {
    if (files.length > 0) {
      const file = files[0]
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
      setBannerFile([file]) // Keep only the latest file in state
    }
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }

  const handleSubmit = async e => {
    e.preventDefault()

    let active = status === true ? 1 : 0
    let is_premium = premium === true ? 1 : 0
    let is_featured = featured === true ? 1 : 0
    let has_breakfast = breakfast === true ? 1 : 0
    let has_lunch = lunch === true ? 1 : 0
    let has_dinner = dinner === true ? 1 : 0
    let has_snack = snack === true ? 1 : 0
    let has_dessert = dessert === true ? 1 : 0
    let has_beverages = beverages === true ? 1 : 0

    const formData = new FormData()

    formData.append("restaurant_name", restaurant_name)
    formData.append("restaurant_slug", restaurant_slug)
    formData.append("keywords", keywords)
    formData.append("restaurant_description", restaurant_description)
    formData.append("restaurant_category_id", restaurant_category_id)
    formData.append("active", active)
    formData.append("no_of_branches", no_of_branches)
    formData.append("timing", timing)
    formData.append("phone_number", phone_number)
    formData.append("is_premium", is_premium)
    formData.append("is_featured", is_featured)
    formData.append("has_breakfast", has_breakfast)
    formData.append("has_lunch", has_lunch)
    formData.append("has_dinner", has_dinner)
    formData.append("has_snack", has_snack)
    formData.append("has_dessert", has_dessert)
    formData.append("has_beverages", has_beverages)
    if (selectedFiles.length > 0) {
      // Append each file from selectedFiles array
      selectedFiles.forEach(file => {
        formData.append("images[]", file) // Use "images[]" to indicate it's an array
      })
    }

    if(bannerFile.length > 0){
      formData.append("banner_image", bannerFile[0])
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/restaurants/update/${id}`,
        {
          method: "POST",
          body: formData,
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        },
      )

      const json = await response.json()

      if (response.ok) {
        if(type === "all"){
          navigate("/all-restaurants")
        }else if(type === "featured"){
          navigate("/featured-restaurants")
        }
       
      }else{
        if(json.message){
          setError(json.message)
        }
       
        if(json.errors.restaurant_category_id){
          setAlertVisible(true)
        }
      
      setTimeout(() => setAlertVisible(false), 10000)
      }
    } catch (error) {
      console.error(error)
    } finally {
      // Clear form fields if necessary
    }
  }
  return (
    <React.Fragment>
      {alertVisible && ( // Conditionally render the alert
        <UncontrolledAlert color="success">
          <strong>Well done!</strong> You successfully added a new restaurant.
        </UncontrolledAlert>
      )}
      {/* form */}
      <Row className="">
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title">Edit Restaurant</h4>

              <AvForm onSubmit={handleSubmit} className="needs-validation mt-4">
                <Row>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Name</Label>
                      <AvField
                        name="restaurant_name"
                        placeholder="Restaurant Name"
                        onChange={e => setName(e.target.value)}
                        type="text"
                        errorMessage="Enter Restaurant Name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={restaurant_name}
                        onBlur={handleBlur}
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Slug</Label>
                      <AvField
                        name="restaurant_slug"
                        placeholder="Restaurant Slug"
                        onChange={e => setSlug(e.target.value)}
                        type="text"
                        errorMessage="Enter Restaurant Slug"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={restaurant_slug}
                      />
                          {error.trim() !== "" && (
                        <div className="invalid-feedback d-block">
                          {error}
                        </div>
                      )}
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">
                        Choose Category
                      </Label>
                      <Select
                        // value={restaurant_category_id}
                        onChange={e => setRestaurantCategoryId(e.value)}
                        options={categories}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                        value={categories.find(
                          option => option.value === restaurant_category_id,
                        )}
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Timing</Label>
                      <AvField
                        name="timing"
                        placeholder="Restaurant Timing"
                        type="text"
                        errorMessage="Enter Restaurant Timing"
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom02"
                        onChange={e => setTiming(e.target.value)}
                        value={timing}
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Keywords</Label>
                      <AvField
                        name="keywords"
                        placeholder="Comma Separated"
                        type="text"
                        errorMessage="Enter Keywords"
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom02"
                        onChange={e => setKeywords(e.target.value)}
                        value={keywords}
                      />
                    </div>
                  </Col>
                </Row>
                <Row md="4">
                  <Col md="12">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Description</Label>
                      <AvField
                        className="form-control"
                        type="textarea"
                        onChange={e => setDescription(e.target.value)}
                        name="restaurant_description"
                        id="address"
                        rows="5"
                        placeholder="Restaurant Description"
                        errorMessage="Description is required."
                        // validate={{
                        //   required: { value: true },
                        // }}
                        value={restaurant_description}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">
                        Number of Branches
                      </Label>
                      <AvField
                        name="branches"
                        placeholder="Number of Branches"
                        type="number"
                        // errorMessage="Please provide number of branches."
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom03"
                        onChange={e => setBranches(e.target.value)}
                        value={no_of_branches}
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom04">Phone Number</Label>
                      <AvField
                        name="phone number"
                        placeholder="03001243121"
                        type="text"
                        // errorMessage="Please provide a valid phone number."
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom04"
                        onChange={e => setPhoneNumber(e.target.value)}
                        value={phone_number}
                      />
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom04">
                        Youtube Video URL
                      </Label>
                      <AvField
                        name="youtube video"
                        placeholder="Enter Youtube Video URL"
                        type="text"
                        errorMessage="Please provide a valid youtube video link."
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom04"
                        onChange={e => setYoutube(e.target.value)}
                      />
                    </div>
                  </Col>
                  <Row md="4">
                    <Col md="6">
                      <div className="mb-3">
                        <label
                          htmlFor="horizontal-email-input"
                          className="col-sm-3 col-form-label"
                        >
                          Image
                        </label>
                        <div className="col-sm-12">
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(acceptedFiles)
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone dz-clickable">
                                <div
                                  className="dz-message needsclick"
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="mb-3">
                                    <i className="mdi mdi-cloud-upload-outline text-muted display-4"></i>
                                  </div>
                                  <h4>Drop files here or click to upload.</h4>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {selectedFiles.length > 0 ? (
                              <>
                                {selectedFiles.map((f, i) => {
                                  return (
                                    <Card
                                      className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      key={i + "-file"}
                                    >
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <img
                                              data-dz-thumbnail=""
                                              height="80"
                                              className="avatar-sm rounded bg-light"
                                              alt={f.name}
                                              src={f.preview}
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {f.name}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>{f.formattedSize}</strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )
                                })}
                              </>
                            ) : (
                              <>
                                {oldImages.length > 0 ? (
                                  <>
                                    {oldImages.map((image, index) => (
                                      <Card
                                        key={index}
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      >
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <img
                                                data-dz-thumbnail=""
                                                height="80"
                                                className="avatar-sm rounded bg-light"
                                                // alt={f.name}
                                                src={image.local_image_small}
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                              >
                                                {image.media_type}
                                              </Link>
                                              <p className="mb-0">
                                                {/* <strong>{f.formattedSize}</strong> */}
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    ))}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </Col>
                  
                  </Row>
                </Row>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="invalidCheck1"
                    checked={premium}
                    onClick={e => setPremium(!premium)}
                  />
                  <label className="form-check-label" htmlFor="invalidCheck1">
                    Premium
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                {/* <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // value={featured}
                    id="invalidCheck2"
                    checked={featured}
                    // defaultChecked={featured}
                    onClick={e => setFeatured(!featured)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck2">
                    Featured
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div> */}

                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // value={featured}
                    id="invalidCheck3"
                    checked={breakfast}
                    // defaultChecked={featured}
                    onClick={e => setBreakFast(!breakfast)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck3">
                    Breakfast
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // value={featured}
                    id="invalidCheck4"
                    checked={lunch}
                    // defaultChecked={featured}
                    onClick={e => setLunch(!lunch)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck4">
                    Lunch
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // value={featured}
                    id="invalidCheck5"
                    checked={dinner}
                    // defaultChecked={featured}
                    onClick={e => setDinner(!dinner)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck5">
                    Dinner
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // value={featured}
                    id="invalidCheck6"
                    checked={snack}
                    // defaultChecked={featured}
                    onClick={e => setSnack(!snack)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck6">
                    Snack
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // value={featured}
                    id="invalidCheck7"
                    checked={dessert}
                    // defaultChecked={featured}
                    onClick={e => setDesert(!dessert)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck7">
                    Dessert
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    // value={featured}
                    id="invalidCheck9"
                    checked={beverages}
                    // defaultChecked={featured}
                    onClick={e => setBeverages(!beverages)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck9">
                      Beverages
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>

                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="invalidCheck8"
                    // value={status}
                    // defaultChecked={status}
                    checked={status}
                    onClick={(e) => setActive(!status)}
                    // required
                  />
                  <label className="form-check-label" htmlFor="invalidCheck8">
                    Active
                  </label>
                  <div className="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
                <Button color="primary" type="submit">
                  Update Restaurant
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* end form */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(EditNewRestaurant)
