import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { connect } from "react-redux"
import Swal from "sweetalert2"
// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

import { Link, useParams } from "react-router-dom"

const AllAreas = props => {
  document.title = "PFP Admin - All Banks"

  const breadcrumbItems = [
    { title: "Areas", link: "#" },
    { title: "All Areas", link: "#" },
  ]

  const [data1, setData] = useState(null)
  const [records, setRecords] = useState([])
  const [update, setUpdate] = useState(0)
  const token = localStorage.getItem("token")
  const location_country_id = 1;
  const location_state_id = 1;
  const location_city_id = 1;

  useEffect(() => {
    props.setBreadcrumbItems("Manage Areas", breadcrumbItems)
    console.log("2 bar")
  }, [props])

  useEffect(() => {
    const fetchAllAreas = async () => {

      const params = new URLSearchParams({
        location_country_id: location_country_id, 
        location_state_id: location_state_id, 
        location_city_id: location_city_id, 
      })

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/locations/areas/list?${params}`,{
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )
      const json = await response.json()

      if (response.ok) {
        setData(json.data)
        setRecords(json.data)
      }else{
        setRecords([])
      }
    }

    fetchAllAreas()
  }, [update, location_country_id])

  const data = {
    columns: [
      {
        label: "ID",
        field: "id",
        sort: false,
        width: 150,
      },
      {
        label: "Name",
        field: "area_name",
        sort: "asc",
        width: 150,
      },


      {
        label: "Action",
        field: "action",
        sort: false,
        width: 100,
      },
    ],
    rows: records.map(row => ({
        area_name: row.area_name,
        id: row.id,

      action: (
        // <div className="d-flex justify-content-center gap-2">
        <div className="d-flex justify-content-end gap-2">
              <Link
            to={`/manage-phases/${row.id}`}
            className="btn btn-sm btn-dark"
          >
            Manage Phases
          </Link>{" "}
          <Link
            to={`/edit-area/${row.id}`}
            className="btn btn-sm btn-primary"
          >
            Edit
          </Link>{" "}
          <button
            onClick={() => handleDelete(row.id)}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>
        </div>
        // </div>
      ),
    })),
  }

  const handleDelete = myId => {
    console.log(myId)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          const deleteUser = async d_id => {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/backend/locations/areas/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  "Authorization": `Bearer ${token}`,
                }
              },
            )
            const json = await response.json()
            if(response.ok){
              swalWithBootstrapButtons.fire({
                title: "Deleted!",
                text: "Area has been deleted.",
                icon: "success",
              })
            }else{
              console.log(json.message)
              swalWithBootstrapButtons.fire({
                title: "Cancelled",
                text: json.message,
                icon: "error",
              })
            }
            setUpdate(update + 1)
          }
          deleteUser(myId)
     
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Area is safe :)",
            icon: "error",
          })
        }
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4 d-flex justify-content-between">All Areas <Link to={`/add-area`}><Button type="button" color="primary" className="waves-effect waves-light">Add New Area</Button></Link></CardTitle>

              <MDBDataTable
                responsive
                bordered
                data={data}
                data-mdb-loading="true"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AllAreas)
