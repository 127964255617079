import React, { useEffect, useRef, useState } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Button, UncontrolledAlert } from "reactstrap"
import { connect } from "react-redux"
import Swal from "sweetalert2"
import Resizer from "react-image-file-resizer"
// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

import { Link } from "react-router-dom"
import { format } from "date-fns"

const AllBanners = props => {
  document.title = "PFP Admin - All Banners"

  const breadcrumbItems = [
    { title: "Media", link: "#" },
    { title: "All Banners", link: "#" },
  ]

  const [data1, setData] = useState(null)
  const [records, setRecords] = useState([])
  const [update, setUpdate] = useState(0)
  const token = localStorage.getItem("token")
  const [image, setImage] = useState("")
  const imageRef = useRef(null)
  const [alertVisible, setAlertVisible] = useState(false)
  const [successVisible, setSuccessVisible] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  useEffect(() => {
    props.setBreadcrumbItems("Manage Banners", breadcrumbItems)
    console.log("2 bar")
  }, [props])

  useEffect(() => {
    const fetchAllCategories = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/banner/list`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      const json = await response.json()

      if (response.ok) {
        setData(json.data)
        setRecords(json.data)
      } else {
        setRecords([])
      }
    }

    fetchAllCategories()
  }, [update])

  const data = {
    columns: [
      {
        label: "ID",
        field: "id",
        sort: false,
        width: 150,
      },
      {
        label: "Image",
        field: "image",
        sort: false,
        width: 150,
      },

      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: false,
        width: 100,
      },
    ],
    rows: records.map(row => ({
      id: row.id,

      date: format(row.created_at, "dd MMM, yyyy"),
      image: (
        <>
          {row.banner_image ? (
            <img height="50px" src={row.banner_image}></img>
          ) : (
            <img height="50px" src="/no-image.png"></img>
          )}
        </>
      ),
      action: (
        // <div className="d-flex justify-content-center gap-2">
        <div className="d-flex justify-content-end gap-2">
          <button
            onClick={() => handleDelete(row.id)}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>
        </div>
        // </div>
      ),
    })),
  }

  const handleDelete = myId => {
    console.log(myId)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          const deleteUser = async d_id => {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/backend/banner/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
            setUpdate(update + 1)
          }
          deleteUser(myId)
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Image has been deleted.",
            icon: "success",
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Image is safe :)",
            icon: "error",
          })
        }
      })
  }
  const handleImageChange = async (e) => {
    e.preventDefault()
    const file = e.target.files[0]

    console.log("hi hit")

    const formData = new FormData()

    formData.append("banner_image", file)

    try{
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/banner/add`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
  
      const json = await response.json()
  
      if(response.ok){
        setSuccess(json.message)
        setSuccessVisible(true)
        setTimeout(() => setSuccessVisible(false), 10000)
  
        setUpdate(update + 1)
      }else{
  
        console.log(json.message)
        setError(json.message)
        setAlertVisible(true)
        setTimeout(() => setAlertVisible(false), 10000)
      }
    }catch(error){
      setError(error)
      setAlertVisible(true)
      setTimeout(() => setAlertVisible(false), 10000)
    }finally{
      setImage("")
   
    }


    // const reader = new FileReader();

    // reader.onload = (event) => {
    //   const img = new Image();
    //   img.src = event.target.result;

    //   img.onload = () => {
    //     const canvas = document.createElement("canvas");
    //     const ctx = canvas.getContext("2d");

    //     canvas.width = 262;
    //     canvas.height = 132;

    //     ctx.drawImage(img, 0, 0, 262, 132);

    //     canvas.toBlob(async (blob) => {
    //       const formData = new FormData();
    //       formData.append("banner_image", blob, file.name);

    //       try {
    //         const response = await fetch(
    //           `${process.env.REACT_APP_API_URL}/api/backend/banner/add`,
    //           {
    //             method: "POST",
    //             body: formData,
    //             headers: {
    //               Authorization: `Bearer ${token}`,
    //             },
    //           }
    //         );

    //         if (response.ok) {
    //           setUpdate(update + 1);
    //         } else {
    //           console.error("Upload failed.");
    //         }
    //       } catch (error) {
    //         console.error("Image upload failed:", error);
    //       }
    //     }, "image/jpeg");
    //   };
    // };

    // reader.readAsDataURL(file);
  }


  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
        {alertVisible && ( // Conditionally render the alert
            <UncontrolledAlert color="danger">
              <strong>Error Uploading Image!</strong> {error}
            </UncontrolledAlert>
          )}
        {successVisible && ( // Conditionally render the alert
            <UncontrolledAlert color="success">
              <strong>Success!</strong> {success}
            </UncontrolledAlert>
          )}
          <Card>
            <CardBody>
              <CardTitle className="h4 d-flex justify-content-between">
                All Banners{" "}
                <Link to={`/add-banner`}><Button type="button" color="primary" className="waves-effect waves-light">Add New Banner</Button></Link>
              </CardTitle>

              <MDBDataTable
                responsive
                bordered
                data={data}
                data-mdb-loading="true"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AllBanners)
