import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
// Redux
import { connect } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// users
import user1 from "../../../assets/images/users/user-1.jpg"
import { ProfileContext } from "App"

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false)

  const [image, setImage] = useState("")

  const token = localStorage.getItem("token")
  const {profileUpdate, setProfileUpdate} = useContext(ProfileContext)

  const navigate = useNavigate()

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/auth/profile`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        )

        const json = await response.json()

        if (response.ok) {
          setImage(json.data.user_image || "")
        } else {
        }
      } catch (error) {
        console.error(error)
      } finally {
        // Clear form fields if necessary
      }
    }

    fetchProfile()
  }, [profileUpdate])

  const handleLogout = async () => {
    const data = {
      token,
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/auth/logout`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
          },
        },
      )

      if (response.ok) {
        localStorage.clear()

        navigate("/login")
      }
    } catch (error) {
      alert(error)
    }
  }

  const handleProfileClick = () => {
    navigate("/profile")
  }

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          id="page-header-user-dropdown"
          tag="button"
        >
          {image !== "" ? (
            <>
              <img
                className="rounded-circle header-profile-user"
                src={image}
                style={{ objectFit: "cover" }}
                alt="Header Avatar"
              />
            </>
          ) : (
            <>
              <img
                className="rounded-circle header-profile-user"
                src="/user.png"
                style={{ objectFit: "cover" }}
                alt="Header Avatar"
              />
            </>
          )}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem onClick={handleProfileClick}>
            {" "}
            <i className="mdi mdi-account-circle font-size-17 text-muted align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="#">
            <i className="mdi mdi-wallet font-size-17 text-muted align-middle me-1"/>
            {props.t("My Wallet")}
          </DropdownItem> */}
          {/* <DropdownItem className="d-flex align-items-center" to="#">
            <i className="mdi mdi-cog font-size-17 text-muted align-middle me-1"></i>
            {props.t("Settings")}<span className="badge bg-success ms-auto">11</span></DropdownItem> */}
          {/* <DropdownItem tag="a" href="auth-lock-screen">
            <i className="mdi mdi-lock-open-outline font-size-17 text-muted align-middle me-1"/>
            {props.t("Lock screen")}
          </DropdownItem> */}

          <div className="dropdown-divider" />
          <button onClick={handleLogout} className="dropdown-item text-danger">
            <i className="mdi mdi-power font-size-17 text-muted align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </button>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  )
}

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
}

const mapStatetoProps = state => {
  const { error, success } = state.Profile
  return { error, success }
}

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu)),
)
