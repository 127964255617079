import React, { useContext, useEffect, useRef, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
  Form,
  UncontrolledAlert,
  Label,
  Button,
} from "reactstrap"

import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { Link, useNavigate } from "react-router-dom"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import Dropzone from "react-dropzone"
import { groups } from "common/data"
import { ProfileContext } from "App"

const UserProfile = props => {

  const {profileUpdate, setProfileUpdate} = useContext(ProfileContext)
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [mobile_no, setMobileNo] = useState("")
  const [password, setPassword] = useState("")
  const [admin_user_group_id, setGroupId] = useState()

  const [image, setImage] = useState("")
  const [passwordError, setPasswordError] = useState(" ")

  //   const [status, setActive] = useState(true)
  const [alertVisible, setAlertVisible] = useState(false)
  const [success, setSuccess] = useState("")
  const [update, setUpdate] = useState(0)
  const [fileChanged, setFileChanged] = useState(false)

  const imageRef = useRef(null)

  const [validationErrors, setValidationErrors] = useState({})

  const token = localStorage.getItem("token")
  document.title = "PFP Admin - Profile"

  const breadcrumbItems = [
    { title: "Admin", link: "#" },
    { title: "Profile", link: "#" },
    // { title: "Form Elements", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Profile", breadcrumbItems)
  })
  const navigate = useNavigate()

  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/auth/profile`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          },
        )

        const json = await response.json()

        if (response.ok) {
          setName(json.data.name || "")
          setEmail(json.data.email || "")
          setMobileNo(json.data.mobile_no || "")
          setImage(json.data.user_image || "")
          setGroupId(json.data.admin_user_group_id)

        } else {
        }
      } catch (error) {
        console.error(error)
      } finally {
        // Clear form fields if necessary
      }
    }

    fetchProfile()
  }, [update])

  const groups = [
    {
      label: "Admin",
      value: 1,
    },
    {
      label: "Data Entry Specialist",
      value: 2,
    },
  ]

  const validateForm = () => {
    let errors = {}
    if (!admin_user_group_id) {
      errors.group_id = "Group is required"
    }
    // if (!restaurant_dish_cuisine_id) {
    //   errors.cuisine = "Cuisine is required";
    // }

    setValidationErrors(errors)
    return Object.keys(errors).length === 0 // Returns true if there are no errors
  }

  const handleSubmit = async e => {
    e.preventDefault()

    await validateForm()

    const formData = new FormData()

    formData.append("name", name)
    formData.append("mobile_no", mobile_no)
    formData.append("password", password)
    formData.append("admin_user_group_id", admin_user_group_id)
    if (fileChanged) {
      if (image !== "") {
        formData.append("image", image)
      }
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/auth/update`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      const json = await response.json()

      if (response.ok) {
        setSuccess(json.message)
        setAlertVisible(true)
        setTimeout(() => setAlertVisible(false), 10000)
        setUpdate(update + 1)
        setPasswordError("")
        setFileChanged(false)
        setProfileUpdate(profileUpdate+1)
      } else {
        setPasswordError(json.message)
      }
    } catch (error) {
      console.error(error)
    } finally {
      // Clear form fields if necessary
    }
  }

  const imageClick = () => {
    imageRef.current.click()
  }
  const handleImageChange = e => {
    setImage(e.target.files[0])
    setFileChanged(true)
  }

  return (
    <React.Fragment>
      {/* form */}
      <Row className="">
        <Col lg="12">
          {alertVisible && ( // Conditionally render the alert
            <UncontrolledAlert color="success">
              <strong>Success!</strong> {success}
            </UncontrolledAlert>
          )}
          <Card>
            <CardBody className="text-center">
              <div className="">
              {fileChanged && image ? (
      // Show the preview of the uploaded image before form submission
      <img
        className="rounded-circle mt-4 mt-sm-0"
        height="200px"
        width="200px"
        alt="uploaded preview"
        role="button"
        src={URL.createObjectURL(image)}
        style={{ objectFit: "cover" }}
      />
    ) : image ? (
      // Show the image from the API if available
      <img
        className="rounded-circle mt-4 mt-sm-0"
        height="200px"
        width="200px"
        alt="api image"
        role="button"
        src={image}
        style={{ objectFit: "cover" }}
      />
    ) : (
      // Fallback to user.png if no image from API and no file uploaded
      <img
        className="rounded-circle mt-4 mt-sm-0"
        height="200px"
        width="200px"
        alt="default user"
        role="button"
        src="user.png"
        style={{ objectFit: "cover" }}
      />
    )}
              </div>
              <div className="mt-2">
                <Button color="primary" onClick={imageClick}>
                  Change Image
                </Button>
              </div>
              <input
                onChange={handleImageChange}
                ref={imageRef}
                accept="image/*"
                className="form-control"
                type="file"
                hidden
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="">
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title">Change Profile Information</h4>

              <AvForm onSubmit={handleSubmit} className="needs-validation mt-4">
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Name</Label>
                      <AvField
                        name="name"
                        placeholder="Full Name"
                        onChange={e => setName(e.target.value)}
                        type="text"
                        errorMessage="Enter Valid Name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={name}
                      />
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Mobile</Label>
                      <AvField
                        name="mobile"
                        placeholder="Enter Mobile Number"
                        onChange={e => setMobileNo(e.target.value)}
                        type="text"
                        errorMessage="Enter Valid Mobile"
                        className="form-control"
                        // validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={mobile_no}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Email</Label>
                      <AvField
                        name="email"
                        placeholder="Enter Email"
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        errorMessage="Enter Valid Email"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={email}
                        disabled
                      />
                      {/* {error.trim() !== "" && (
                        <div className="invalid-feedback d-block">
                          {error}
                        </div>
                      )} */}
                    </div>
                  </Col>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Password</Label>
                      <AvField
                        name="password"
                        placeholder="Must be atleast 6 Characters"
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                        errorMessage="Enter Valid Password"
                        className="form-control"
                        // validate={{
                        //   required: {
                        //     value: true,
                        //     errorMessage: "Password is required",
                        //   },
                        //   minLength: {
                        //     value: 6,
                        //     errorMessage:
                        //       "Password must be at least 6 characters",
                        //   },
                        // }}
                        id="validationCustom01"
                      />

                      {(passwordError || "").trim() !== "" && (
                        <div className="invalid-feedback d-block">
                          {passwordError}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Choose Group</Label>
                      <Select
                        // value={restaurant_category_id}
                        onChange={e => setGroupId(e.value)}
                        options={groups}
                        value={groups.find(
                          group => group.value === admin_user_group_id,
                        )}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                      />

                      {validationErrors.group_id && (
                        <div className="invalid-feedback d-block">
                          {validationErrors.group_id}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Button color="primary" type="submit">
                  Update
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* end form */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(UserProfile)
