import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { connect } from "react-redux"
import Swal from "sweetalert2"
// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions"

import { Link } from "react-router-dom"
import { format } from "date-fns"

const AllRejectedRestaurantReviews = props => {
  document.title = "PFP Admin - All Rejected Reviews"

  const breadcrumbItems = [
    { title: "Rejected Restaurant Reviews", link: "#" },
    { title: "All Rejected Restaurant Reviews", link: "#" },
  ]

  const [data1, setData] = useState(null)
  const [records, setRecords] = useState([])
  const [update, setUpdate] = useState(0)
  const token = localStorage.getItem("token")

  useEffect(() => {
    props.setBreadcrumbItems(
      "Manage Rejected Restaurant Reviews",
      breadcrumbItems,
    )
  }, [props])

  useEffect(() => {
    const fetchAllCategories = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/restaurant/reviews/list?is_approved=rejected`,{
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )
      const json = await response.json()

      if (response.ok) {
        setData(json.data)
        setRecords(json.data)
      } else {
        setRecords([])
      }
    }

    fetchAllCategories()
  }, [update])

  const data = {
    columns: [

      {
        label: "User Name",
        field: "user_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Restaurant",
        field: "restaurant_name",
        sort: "asc",
        width: 150,
      },

      {
        label: "Review",
        field: "review",
        sort: "asc",
        width: 270,
      },
      {
        label: "Food",
        field: "food",
        sort: "asc",
        width: 150,
      },
      {
        label: "Service",
        field: "service",
        sort: "asc",
        width: 150,
      },
      {
        label: "Ambiance",
        field: "ambiance",
        sort: "asc",
        width: 150,
      },
      {
        label: "Variety",
        field: "variety",
        sort: "asc",
        width: 150,
      },
      {
        label: "Rejection Date",
        field: "ap_date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Date Reviewed",
        field: "date",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: false,
        width: 100,
      },
    ],
    rows: records.map(row => ({
      user_name: row.user_name,
      restaurant_name: row.restaurant_name,
      date: format(row.created_at, "MMM dd, yyyy - hh:mm:ss"),
      ap_date: format(row.updated_at, "MMM dd, yyyy - hh:mm:ss"),
      review: <>{row.review} <br/>
      {
        row.files.length > 0? 
        <>
        {
          row.files.map((file)=>(
            <img height="80px" alt="recipe" className="mx-1" src={file.image_url}/>
          ))
        }
        </>
        :
        <></>
      }
      </>,
      food: row.food,
      service: row.service,
      ambiance: row.ambiance,
      variety: row.variety,

      action: (
        // <div className="d-flex justify-content-center gap-2">
        <div className="d-flex justify-content-end gap-2">
          {/* <Link
     
            className="btn btn-sm btn-primary"
          >
            Edit
          </Link>{" "} */}
          <button
            onClick={() => handleDelete(row.id)}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>
        </div>
        // </div>
      ),
    })),
  }

  const handleDelete = myId => {
    console.log(myId)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          const deleteUser = async d_id => {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/backend/restaurant/reviews/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  "Authorization": `Bearer ${token}`,
                }
              },
            )
            setUpdate(update + 1)
          }
          deleteUser(myId)
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Review has been deleted.",
            icon: "success",
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Review is safe :)",
            icon: "error",
          })
        }
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4 d-flex justify-content-between">
                All Rejected Restaurant Reviews
              </CardTitle>

              <MDBDataTable
                responsive
                bordered
                data={data}
                data-mdb-loading="true"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(
  AllRejectedRestaurantReviews,
)
