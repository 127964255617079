import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
  Form,
  UncontrolledAlert,
} from "reactstrap"

import { connect } from "react-redux"
import Dropzone from "react-dropzone"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { Link, useNavigate } from "react-router-dom"

const AddNewArea = props => {
  const [area_name, setName] = useState("")
 
  const [alertVisible, setAlertVisible] = useState(false)
  const location_country_id = 1;
  const location_state_id = 1;
  const location_city_id = 1;
  const token = localStorage.getItem("token")

  document.title = "PFP Admin - Add New Area"

  const breadcrumbItems = [
    { title: "Areas", link: "#" },
    { title: "Add New Area", link: "#" },
    // { title: "Form Elements", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Add New Area", breadcrumbItems)
  }, [])


  /**
   * Formats the size
   */

  const navigate = useNavigate()

  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)

  const handleSubmit = async e => {
    e.preventDefault()


    const data = {
     
      location_country_id,
      location_state_id,
      location_city_id,
      area_name
    }
    
      try {
        console.log(data)
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/locations/areas/add`,
          {
            method: "POST",
            body: JSON.stringify(data),  
            headers: {
              "Content-Type": "Application/json",
              "Authorization": `Bearer ${token}`,
            }
          },
        )

        const json = await response.json()

        if (response.ok) {
          navigate("/all-areas")
          // setAlertVisible(true)
          // setTimeout(() => setAlertVisible(false), 5000)
        }
      } catch (error) {
      } finally {
      }

  }

  return (
    <React.Fragment>
      {alertVisible && ( // Conditionally render the alert
        <UncontrolledAlert color="success">
          <strong>Well done!</strong> You successfully added a new area.
        </UncontrolledAlert>
      )}
      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">Add New Area</h4>

              <Row className="">
                <Col lg={6} className="">
                  <div className="mt-5 mt-lg-4">
                    {/* <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Horizontal form</h5> */}

                    <form onSubmit={handleSubmit}>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-3 col-form-label"
                        >
                          Name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            onChange={e => setName(e.target.value)}
                            className="form-control"
                            id="horizontal-firstname-input"
                            placeholder="Enter area name"
                            required
                          />
                        </div>
                       
                      </div>
                      <div className="row justify-content-end">
                        <div className="col-sm-9">
                      
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Add Area
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* end form */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AddNewArea)
