import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
  Form,
  UncontrolledAlert,
} from "reactstrap"

import { connect } from "react-redux"
import Dropzone from "react-dropzone"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { Link, useNavigate, useParams } from "react-router-dom"

const EditNewPhase = props => {

  const {id, areaId} = useParams()
  const [phase_name, setName] = useState("")
 
  const [alertVisible, setAlertVisible] = useState(false)
  const location_country_id = 1;
  const location_state_id = 1;
  const location_city_id = 1;
  const token = localStorage.getItem("token")

  document.title = "PFP Admin - Update Phase"

  const breadcrumbItems = [
    { title: "Phases", link: "#" },
    { title: "Update Phase", link: "#" },
    // { title: "Form Elements", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Update Phase", breadcrumbItems)
  }, [])


  /**
   * Formats the size
   */

  const navigate = useNavigate()

  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)

  useEffect(()=>{

    const fetchArea = async () => {

      try{
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/backend/locations/phases/${id}`, {
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )
  
      const json = await response.json()
  
      if(response.ok){
  
        setName(json.data.phase_name || "")
      }
      }
      catch(error){

      }

    }

    fetchArea()

  }, [id])

  const handleSubmit = async e => {
    e.preventDefault()


    const location_area_id = await areaId;

    const data = {
     
      location_country_id,
      location_state_id,
      location_city_id,
      location_area_id,
      phase_name
    }
    
      try {
        console.log(data)
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/locations/phases/update/${id}`,
          {
            method: "POST",
            body: JSON.stringify(data),  
            headers: {
              "Content-Type": "Application/json",
              "Authorization": `Bearer ${token}`,
            }
          },
        )

        const json = await response.json()

        if (response.ok) {
          navigate(`/manage-phases/${areaId}`)
          // setAlertVisible(true)
          // setTimeout(() => setAlertVisible(false), 5000)
        }
      } catch (error) {
      } finally {
      }

  }

  return (
    <React.Fragment>
      {alertVisible && ( // Conditionally render the alert
        <UncontrolledAlert color="success">
          <strong>Well done!</strong> You successfully added a new area.
        </UncontrolledAlert>
      )}
      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">Update Phase</h4>

              <Row className="">
                <Col lg={6} className="">
                  <div className="mt-5 mt-lg-4">
                    {/* <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Horizontal form</h5> */}

                    <form onSubmit={handleSubmit}>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-3 col-form-label"
                        >
                          Name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            onChange={e => setName(e.target.value)}
                            className="form-control"
                            id="horizontal-firstname-input"
                            placeholder="Enter area name"
                            required
                            value={phase_name}
                          />
                        </div>
                       
                      </div>
                      <div className="row justify-content-end">
                        <div className="col-sm-9">
                      
                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Update Phase
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* end form */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(EditNewPhase)
