import React, { useEffect, useMemo, useState } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { connect } from "react-redux"
import Swal from "sweetalert2"
// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"


import { Link, useParams } from "react-router-dom"

const AllCards = props => {
  document.title = "PFP Admin - All Locations"

  // const id = "37"
  const {id} = useParams()

  const breadcrumbItems = [
    { title: "Banks", link: "#" },
    { title: "Manage Cards", link: "#" },
  ]

  const [data1, setData] = useState(null)
  const [records, setRecords] = useState([])
  const [update, setUpdate] = useState(0)
  const token = localStorage.getItem("token")

  useEffect(() => {
    props.setBreadcrumbItems("Manage Cards", breadcrumbItems)
  
  }, [props]) 

  useEffect(() => {
    const fetchLocations = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/bank/cards/list/${id}`,{
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )
      const json = await response.json()

      if (response.ok) {
        setData(json.data)
        setRecords(json.data)
       
      }else{
        setRecords([])
      }
    }

    fetchLocations()
  }, [id, update])

  const data = useMemo(() => ({
    columns: [
      {
        label: "Image",
        field: "image",
        sort: false,
        width: 150,
      },
      {
        label: "Bank Name",
        field: "bank_name",
        sort: false,
        width: 150,
      },
      {
        label: "Card Type",
        field: "card_type",
        sort: false,
        width: 150,
      },
      {
        label: "Card Name",
        field: "card_name",
        sort: false,
        width: 150,
      },
      {
        label: "Status",
        field: "active",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: false,
        width: 100,
      },
    ],
    rows: records.map((row) => ({
      bank_name: row.bank_name,
      card_type: row.card_type,
      card_name: row.card_name,
      image: (
        <>
          <img height="50" src={row.card_image_url} alt="Card" />
        </>
      ),
      active: row.active === 1 ? "Active" : "Inactive",
      action: (
        <div className="d-flex justify-content-end gap-2">
          <Link to={`/edit-card/${row.id}/${id}`} className="btn btn-sm btn-primary">
            Edit
          </Link>{" "}
          <button onClick={() => handleDelete(row.id)} className="btn btn-sm btn-danger">
            Delete
          </button>
        </div>
      ),
    })),
  }), [records, id]);

  const handleDelete = myId => {
    console.log(myId)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          const deleteUser = async d_id => {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/backend/bank/cards/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  "Authorization": `Bearer ${token}`,
                }
              },
            )
            setUpdate(update + 1)
          }
          deleteUser(myId)
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Card has been deleted.",
            icon: "success",
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Card is safe :)",
            icon: "error",
          })
        }
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4 d-flex justify-content-between">All Cards <Link to={`/add-card/${id}`}><Button type="button" color="primary" className="waves-effect waves-light">Add New Card</Button></Link></CardTitle>
             

              <MDBDataTable
                responsive
                bordered
                data={data}
                data-mdb-loading="true"
                paging={true}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AllCards)
