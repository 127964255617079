import React, { useEffect, useState } from "react"
import { MDBDataTable } from "mdbreact"
import { Row, Col, Card, CardBody, CardTitle, Button } from "reactstrap"
import { connect } from "react-redux"
import Swal from "sweetalert2"
// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"

import { Link } from "react-router-dom"
import { format } from "date-fns"

const Admin = props => {
  document.title = "PFP Admin - All Admin"

  const breadcrumbItems = [
    { title: "Admin", link: "#" },
    { title: "All Admins", link: "#" },
  ]

  const [data1, setData] = useState(null)
  const [records, setRecords] = useState([])
  const [update, setUpdate] = useState(0)
  const token = localStorage.getItem("token")

  useEffect(() => {
    props.setBreadcrumbItems("Manage Admins", breadcrumbItems)
    console.log("2 bar")
  }, [props])

  useEffect(() => {
    const fetchAllCategories = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/admin/list`,{
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        }
      )
      const json = await response.json()

      if (response.ok) {
        setData(json.data)
        setRecords(json.data)
      }else{
        setRecords([])
      }
    }

    fetchAllCategories()
  }, [update])

  const data = {
    columns: [
      {
        label: "Image",
        field: "image",
        sort: false,
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: false,
        width: 150,
      },
      {
        label: "Email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile_no",
        sort: "asc",
        width: 150,
      },
      {
        label: "Group",
        field: "group_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 150,
      },

      {
        label: "Status",
        field: "active",
        sort: "asc",
        width: 270,
      },
      {
        label: "Action",
        field: "action",
        sort: false,
        width: 100,
      },
    ],
    rows: records.map(row => ({
        name: row.name,
        email: row.email,
        mobile_no: row.mobile_no,
        group_name: row.group_name,
        date: format(row.created_at, "dd MMM, yyyy"),
  
      active: row.active === 1 ? "Active" : "Inactive",
      image: (
        <>
          {row.user_image ? (
            <img height="50px" src={row.user_image}></img>
          ) : (
            <img height="50px" src="/no-image.png"></img>
          )}
        </>
      ),
      action: (
        // <div className="d-flex justify-content-center gap-2">
        <div className="d-flex justify-content-end gap-2">
    <Link
               to={`/edit-admin/${row.id}`}
            className="btn btn-sm btn-primary"
          >
            Edit
          </Link>{" "}
          <button
            onClick={() => handleDelete(row.id)}
            className="btn btn-sm btn-danger"
          >
            Delete
          </button>
        </div>
        // </div>
      ),
    })),
  }

  const handleDelete = myId => {
    console.log(myId)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          const deleteUser = async d_id => {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/backend/admin/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  "Authorization": `Bearer ${token}`,
                }
              },
            )
            setUpdate(update + 1)
          }
          deleteUser(myId)
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "User has been deleted.",
            icon: "success",
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "User is safe :)",
            icon: "error",
          })
        }
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <CardTitle className="h4 d-flex justify-content-between">All Admins  <Link to={`/add-admin`}><Button type="button" color="primary" className="waves-effect waves-light">Add New Admin</Button></Link></CardTitle>

              <MDBDataTable
                responsive
                bordered
                data={data}
                data-mdb-loading="true"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(Admin)
