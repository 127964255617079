import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
  Form,
  UncontrolledAlert,
  Label,
  Button,
} from "reactstrap"

import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { Link, useNavigate } from "react-router-dom"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import Dropzone from "react-dropzone"
import { groups } from "common/data"

const AddAdmin = props => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [admin_user_group_id, setGroupId] = useState()
  const [no_of_branches, setBranches] = useState(0)

  //   const [status, setActive] = useState(true)
  const [alertVisible, setAlertVisible] = useState(false)


  const [validationErrors, setValidationErrors] = useState({})

  const token = localStorage.getItem("token")
  document.title = "PFP Admin - Add New Admin"

  const breadcrumbItems = [
    { title: "Admin", link: "#" },
    { title: "Add New Admin", link: "#" },
    // { title: "Form Elements", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Add New Admin", breadcrumbItems)
  })
  const navigate = useNavigate()

  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)

  const groups = [
    {
      label: "Admin",
      value: 1,
    },
    {
      label: "Data Entry Specialist",
      value: 2,
    },
  ]

  const validateForm = () => {
    let errors = {}
    if (!admin_user_group_id) {
      errors.group_id = "Group is required"
    }
    // if (!restaurant_dish_cuisine_id) {
    //   errors.cuisine = "Cuisine is required";
    // }

    setValidationErrors(errors)
    return Object.keys(errors).length === 0 // Returns true if there are no errors
  }

  const handleSubmit = async e => {
    e.preventDefault()

    await validateForm()

    const data = {
      name,
      email,
      password,
      admin_user_group_id,
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/admin/register`,
        {
          method: "POST",
          body: JSON.stringify(data),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        },
      )

      const json = await response.json()

      if (response.ok) {

        navigate("/all-admins")
      } else {
    
      }
    } catch (error) {
      console.error(error)

    } finally {
      // Clear form fields if necessary
    }
  }

  return (
    <React.Fragment>
      {/* form */}
      <Row className="">
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title">Add New Admin</h4>

              <AvForm onSubmit={handleSubmit} className="needs-validation mt-4">
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Name</Label>
                      <AvField
                        name="name"
                        placeholder="Full Name"
                        onChange={e => setName(e.target.value)}
                        type="text"
                        errorMessage="Enter Valid Name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Password</Label>
                      <AvField
                        name="password"
                        placeholder="Must be atleast 6 Characters"
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                        errorMessage="Enter Valid Password"
                        className="form-control"
                        validate={{
                          required: {
                            value: true,
                            errorMessage: "Password is required",
                          },
                          minLength: {
                            value: 6,
                            errorMessage:
                              "Password must be at least 6 characters",
                          },
                        }}
                        id="validationCustom01"
                        value={password}
                      />
                      {/* {passwordError.trim() !== "" && (
                        <div className="invalid-feedback d-block">
                          {passwordError}
                        </div>
                       )}  */}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Email</Label>
                      <AvField
                        name="email"
                        placeholder="Enter Email"
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        errorMessage="Enter Valid Email"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={email}
                      />
                      {/* {error.trim() !== "" && (
                        <div className="invalid-feedback d-block">
                          {error}
                        </div>
                      )} */}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Choose Group</Label>
                      <Select
                        // value={restaurant_category_id}
                        onChange={e => setGroupId(e.value)}
                        options={groups}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                      />

                      {validationErrors.group_id && (
                        <div className="invalid-feedback d-block">
                          {validationErrors.group_id}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Button color="primary" type="submit">
                  Add Admin
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* end form */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AddAdmin)
