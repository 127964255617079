import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
  Form,
  UncontrolledAlert,
  Label,
  Button,
} from "reactstrap"

import { connect } from "react-redux"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { Link, useNavigate, useParams } from "react-router-dom"
import { AvField, AvForm } from "availity-reactstrap-validation"
import Select from "react-select"
import Dropzone from "react-dropzone"
import { groups } from "common/data"

const EditAdmin = props => {

  const {id} = useParams()
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [admin_user_group_id, setGroupId] = useState()
  const [no_of_branches, setBranches] = useState(0)
  const [passwordError, setPasswordError] = useState("")
  //   const [status, setActive] = useState(true)
  const [alertVisible, setAlertVisible] = useState(false)

  const [validationErrors, setValidationErrors] = useState({})

  const token = localStorage.getItem("token")
  document.title = "PFP Admin - Edit Admin"

  const breadcrumbItems = [
    { title: "Admin", link: "#" },
    { title: "Edit Admin", link: "#" },
    // { title: "Form Elements", link: "#" },
  ]

  useEffect(() => {
    props.setBreadcrumbItems("Edit Admin", breadcrumbItems)
  })
  const navigate = useNavigate()

  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)

  const groups = [
    {
      label: "Admin",
      value: 1,
    },
    {
      label: "Data Entry Specialist",
      value: 2,
    },
  ]

  useEffect(()=>{

    const getUser = async () => {

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/backend/admin/${id}`,{
        headers: {
          Authorization: `Bearer ${token}`
        }
      })

      const json = await response.json()

      if(response.ok){
        setName(json.data.name || "")
        setEmail(json.data.email || "")
        setGroupId(json.data.admin_user_group_id)
      }
    }

    getUser()

  }, [id])
  const validateForm = () => {
    let errors = {}
    if (!admin_user_group_id) {
      errors.group_id = "Group is required"
    }
    // if (!restaurant_dish_cuisine_id) {
    //   errors.cuisine = "Cuisine is required";
    // }

    setValidationErrors(errors)
    return Object.keys(errors).length === 0 // Returns true if there are no errors
  }

  const handleSubmit = async e => {
    e.preventDefault()

    await validateForm()



    const formData = new FormData()


      formData.append('name', name)
      formData.append('password', password)
      formData.append('admin_user_group_id', admin_user_group_id)


    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/admin/update/${id}`,
        {
          method: "POST",
          body: formData,
          headers: {
         
            Authorization: `Bearer ${token}`,
          },
        },
      )

      const json = await response.json()

      if (response.ok) {
        navigate("/all-admins")
      } else {
       setPasswordError(json.message)
      }
    } catch (error) {
      console.error(error)
    } finally {
      // Clear form fields if necessary
    }
  }

  return (
    <React.Fragment>
      {/* form */}
      <Row className="">
        <Col lg="12">
          <Card>
            <CardBody>
              <h4 className="card-title">Edit Admin</h4>

              <AvForm onSubmit={handleSubmit} className="needs-validation mt-4">
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Name</Label>
                      <AvField
                        name="name"
                        placeholder="Full Name"
                        onChange={e => setName(e.target.value)}
                        type="text"
                        errorMessage="Enter Valid Name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={name}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Password</Label>
                      <AvField
                        name="password"
                        placeholder="Must be atleast 6 Characters"
                        onChange={e => setPassword(e.target.value)}
                        type="password"
                        errorMessage="Enter Valid Password"
                        className="form-control"
               
                        // validate={{
                        //   required: {
                        //     value: true,
                        //     errorMessage: "Password is required",
                        //   },
                        //   minLength: {
                        //     value: 6,
                        //     errorMessage:
                        //       "Password must be at least 6 characters",
                        //   },
                        // }}
                        id="validationCustom01"
                        
                      />
                      {passwordError.trim() !== "" && (
                        <div className="invalid-feedback d-block">
                          {passwordError}
                        </div>
                       )} 
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom01">Email</Label>
                      <AvField
                        name="email"
                        placeholder="Enter Email"
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        errorMessage="Enter Valid Email"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom01"
                        value={email}
                        disabled
                      />
                 
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md="6">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Choose Group</Label>
                      <Select
                        // value={restaurant_category_id}
                        onChange={e => setGroupId(e.value)}
                        options={groups}
                        value={groups.find(group => group.value === admin_user_group_id)}
                        classNamePrefix="select2-selection"
                        validate={{ required: { value: true } }}
                      />

                      {validationErrors.group_id && (
                        <div className="invalid-feedback d-block">
                          {validationErrors.group_id}
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>

                <Button color="primary" type="submit">
                  Update Admin
                </Button>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* end form */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(EditAdmin)
