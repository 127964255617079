import React, { useEffect, useMemo, useState } from "react"
import { MDBDataTable } from "mdbreact"
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  UncontrolledAlert,
} from "reactstrap"
import { connect } from "react-redux"
import Swal from "sweetalert2"
// Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../../store/actions"

import { Link } from "react-router-dom"
import { useRef } from "react"
import { format } from "date-fns"

const AllPendingRestaurantReviews = props => {
  document.title = "PFP Admin - All Banks"

  const breadcrumbItems = [
    { title: "Pending Restaurant Reviews", link: "#" },
    { title: "All Pending Restaurant Reviews", link: "#" },
  ]

  const [data1, setData] = useState(null)
  const [records, setRecords] = useState([])
  const [update, setUpdate] = useState(0)
  const [alertVisible, setAlertVisible] = useState(false)
  const [error, setError] = useState("")
  const token = localStorage.getItem("token")
  const deliverModalCloseButton = useRef(null)
  const [activeModal, setActiveModal] = useState(null)
  const [reasons, setReason] = useState("")

  // Trigger the modal by setting the active row's ID
  const handleModalOpen = id => {
    console.log(id)
    setActiveModal(id)
  }

  // Close the modal
  const handleModalClose = () => {
    setActiveModal(null)
  }

  useEffect(() => {
    props.setBreadcrumbItems(
      "Manage Pending Restaurant Reviews",
      breadcrumbItems,
    )
  }, [props])

  useEffect(() => {
    const fetchAllCategories = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/backend/restaurant/reviews/list?is_approved=pending`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      const json = await response.json()

      if (response.ok) {
        setData(json.data)
        setRecords(json.data)
      } else {
        setRecords([])
      }
    }

    fetchAllCategories()
  }, [update])

  const handleApprove = async (approved, id) => {
    const data = approved === "rejected" ? { approved, reasons } : { approved }
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/backend/restaurant/reviews/approval/${id}`,
      {
        method: "PATCH",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "Application/json",

          Authorization: `Bearer ${token}`,
        },
      },
    )

    const json = await response.json()

    if (response.ok) {
      setError(json.message)
      setAlertVisible(true)
      setTimeout(() => setAlertVisible(false), 10000)

      setUpdate(update + 1)
      setActiveModal(null)
    }
  }

  const data = useMemo(
    () => ({
      columns: [
        {
          label: "User Name",
          field: "user_name",
          sort: "asc",
          width: 150,
        },
        {
          label: "Restaurant",
          field: "restaurant_name",
          sort: "asc",
          width: 150,
        },

        {
          label: "Review",
          field: "review",
          sort: "asc",
          width: 270,
        },
        {
          label: "Food",
          field: "food",
          sort: "asc",
          width: 150,
        },
        {
          label: "Service",
          field: "service",
          sort: "asc",
          width: 150,
        },
        {
          label: "Ambiance",
          field: "ambiance",
          sort: "asc",
          width: 150,
        },
        {
          label: "Variety",
          field: "variety",
          sort: "asc",
          width: 150,
        },
        {
          label: "Date Reviewed",
          field: "date",
          sort: "asc",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          sort: false,
          width: 100,
        },
      ],
      rows: records.map(row => ({
        user_name: row.user_name,
        restaurant_name: row.restaurant_name,
        date: format(row.created_at, "MMM dd, yyyy - hh:mm:ss"),
        review: (
          <>
            {row.review} <br />
            {row.files.length > 0 ? (
              <>
                {row.files.map(file => (
                  <img
                    height="80px"
                    alt="recipe"
                    className="mx-1"
                    src={file.image_url}
                  />
                ))}
              </>
            ) : (
              <></>
            )}
          </>
        ),
        food: row.food,
        service: row.service,
        ambiance: row.ambiance,
        variety: row.variety,

        action: (
          // <div className="d-flex justify-content-center gap-2">
          <div className="d-flex justify-content-end gap-2">
            <button
              onClick={() => handleModalOpen(row.id)}
              className="btn btn-sm btn-warning"
            >
              Reject
            </button>
          
            <button
              onClick={() => handleApprove("approved", row.id)}
              className="btn btn-sm"
              style={{ backgroundColor: "#24A97B", color: "white" }}
            >
              Approve
            </button>{" "}
            {/* <Link className="btn btn-sm btn-primary">
              Edit
            </Link>{" "}
            <button
              onClick={() => handleDelete(row.id)}
              className="btn btn-sm btn-danger"
            >
              Delete
            </button> */}
          </div>
          // </div>
        ),
      })),
    }),
    [records, update],
  )

  const handleDelete = myId => {
    console.log(myId)
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: "btn btn-success btn-sm ms-2",
        cancelButton: "btn btn-sm",
        popup: "my-custom-modal",
        title: "my-custom-title",
        icon: "my-custom-icon",
        content: "my-custom-text",
      },
      buttonsStyling: false,
    })
    swalWithBootstrapButtons
      .fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
      })
      .then(result => {
        if (result.isConfirmed) {
          const deleteUser = async d_id => {
            const response = await fetch(
              `${process.env.REACT_APP_API_URL}/api/backend/recipes/delete/${d_id}`,
              {
                method: "DELETE",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              },
            )
            setUpdate(update + 1)
          }
          deleteUser(myId)
          swalWithBootstrapButtons.fire({
            title: "Deleted!",
            text: "Review has been deleted.",
            icon: "success",
          })
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          swalWithBootstrapButtons.fire({
            title: "Cancelled",
            text: "Review is safe :)",
            icon: "error",
          })
        }
      })
  }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          {alertVisible && ( // Conditionally render the alert
            <UncontrolledAlert color="success">
              <strong>Success!</strong> {error}
            </UncontrolledAlert>
          )}
          <Card>
            <CardBody>
              <CardTitle className="h4 d-flex justify-content-between">
                All Pending Restaurant Reviews
              </CardTitle>
              {activeModal && (
              <div
                className="modal fade show"
                // id={`${row.id}deliverModal`}
                tabIndex={-1}
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: "block", backdropFilter: "blur(5px)" }}
              >
                <div className="modal-dialog modal-dialog-centered">
                  <form
                    className="modal-content"
                    onSubmit={e => {
                      e.preventDefault() // Prevent page reload
                      handleApprove("rejected", activeModal) // Call the function to handle rejection
                    }}
                  >
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">
                        Reason of Rejection
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={handleModalClose}
                      ></button>
                    </div>
                    <div className="modal-body">
                      <textarea
                        type="text"
                        className="form-control"
                        onChange={e => setReason(e.target.value)}
                        placeholder="Enter Valid Reason"
                        rows={3}
                        required
                      ></textarea>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" className="btn btn-danger">
                        Confirm Reject
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            )}
              <MDBDataTable
                responsive
                bordered
                data={data}
                data-mdb-loading="true"
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(
  AllPendingRestaurantReviews,
)
