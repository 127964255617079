import React, { useEffect, useState } from "react"

import {
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  FormGroup,
  Form,
  UncontrolledAlert,
} from "reactstrap"

import { connect } from "react-redux"
import Dropzone from "react-dropzone"

//Import Action to copy breadcrumb items from local state to redux state
import { setBreadcrumbItems } from "../../store/actions"
import { Link, useNavigate } from "react-router-dom"

const AddNewCategory = props => {
  const [category_name, setName] = useState("")
  const [category_description, setDescription] = useState("")
  // const [category_description, setDescription] = useState("")
  const [status, setActive] = useState(true)
  const [alertVisible, setAlertVisible] = useState(false)
  const [error, setError] = useState("")
  const [category_slug, setSlug] = useState("");
  const token = localStorage.getItem('token')

  document.title = "PFP Admin - Add New Dish Category"

  const breadcrumbItems = [
    { title: "Restaurant", link: "#" },
    { title: "Add New Dish Category", link: "#" },
    // { title: "Form Elements", link: "#" },
  ]
    // Function to fetch slug from the API
    const fetchSlug = async (name) => {
      try {
        const params = new URLSearchParams({
          slug_string: name
        })
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/backend/slug/generate?${params}`, {
          headers: {
            "Authorization": `Bearer ${token}`,
          }
        });
        const json = await response.json();
   
        if (response.ok) {
          setSlug(json.data.slug); 
          console.log(json.data.slug)
        } else {
          console.error("Failed to generate slug");
        }
      } catch (error) {
        console.error("Error fetching slug:", error);
      }
    };
  
  
     // Handle when the input field loses focus (onBlur event)
     const handleBlur = () => {
      if (category_name) {
        fetchSlug(category_name);
      }
    };
  
  useEffect(() => {
    props.setBreadcrumbItems("Add Restaurant Dish Category", breadcrumbItems)
  }, [])

  const [selectedFiles, setselectedFiles] = useState([])

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      }),
    )
    setselectedFiles(files)
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
  }
  const navigate = useNavigate()

  const [toggleSwitch, settoggleSwitch] = useState(true)
  const [toggleSwitchSize, settoggleSwitchSize] = useState(true)

  const handleSubmit = async e => {
    e.preventDefault()

    let active = 0
    if (status === true) {
      active = 1
    } else if (status === false) {
      active = 0
    }


    const dish = {
      category_name,
      category_description,
      category_slug,
      active
    }

    // const formData = new FormData()

    // if (selectedFiles.length === 0) {
    //   formData.append("category_name", category_name)
    //   formData.append("category_description", category_description)
    //   formData.append("active", active)
    // } else {
    //   formData.append("category_name", category_name)
    //   formData.append("category_description", category_description)
    //   formData.append("active", active)
    //   formData.append("image", selectedFiles[0])
    // }
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/backend/restaurants/dishes/category/add`,
          {
            method: "POST",
            body: JSON.stringify(dish),
            headers: {
              "Content-Type": "Application/json",
              
                "Authorization": `Bearer ${token}`,
              
            }
          },
        )

        const json = await response.json()

        if (response.ok) {
          navigate("/all-restaurant-dish-categories")
        
        }else{
          setError(json.message)
            setAlertVisible(true)
          setTimeout(() => setAlertVisible(false), 10000)
          console.log(json.errors.category_name)
        }
      } catch (error) {
      } finally {
      }
  
  }

  return (
    <React.Fragment>
      {alertVisible && ( // Conditionally render the alert
        <UncontrolledAlert color="danger">
          <strong>Error!</strong> {error}
        </UncontrolledAlert>
      )}
      {/* form */}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <h4 className="card-title">Add Restaurant Dish Category</h4>

              <Row className="">
                <Col lg={6} className="">
                  <div className="mt-5 mt-lg-4">
                    {/* <h5 className="font-size-14 mb-4"><i className="mdi mdi-arrow-right text-primary me-1"></i> Horizontal form</h5> */}

                    <form onSubmit={handleSubmit}>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-3 col-form-label"
                        >
                          Name
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            onChange={e => setName(e.target.value)}
                            className="form-control"
                            id="horizontal-firstname-input"
                              required
                            placeholder="Enter category name"
                            onBlur={handleBlur}
                        
                          />
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-firstname-input"
                          className="col-sm-3 col-form-label"
                        >
                          Slug
                        </label>
                        <div className="col-sm-9">
                          <input
                            type="text"
                            onChange={e => setSlug(e.target.value)}
                            className="form-control"
                            id="horizontal-firstname-input"
                              required
                            placeholder="Enter category slug"
                            value={category_slug}
                        
                          />
                            {error.trim() !== "" && (
                        <div className="invalid-feedback d-block">
                          {error}
                        </div>
                      )}
                        </div>
                      </div>
                      <div className="row mb-4">
                        <label
                          htmlFor="horizontal-email-input"
                          className="col-sm-3 col-form-label"
                        >
                          Description
                        </label>
                        <div className="col-sm-9">
                          <textarea
                            type="text"
                            onChange={e => setDescription(e.target.value)}
                            rows={4}
                            className="form-control"
                            id="horizontal-email-input"
                            placeholder="Describe the category"
                          />
                        </div>
                      </div>
                 

                      <div className="row justify-content-end">
                        <div className="col-sm-9">
                          <div className="form-check mb-4">
                            <input
                              type="checkbox"
                              // checked={status}
                              value={status}
                              defaultChecked={status}
                              className="form-check-input"
                              onChange={(e) => setActive(e.target.checked)}
                              id="horizontal-customCheck"
                            
                            />
                            <label
                              className="form-check-label"
                              htmlFor="horizontal-customCheck"
                            >
                              Active
                            </label>
                          </div>

                          <div>
                            <button
                              type="submit"
                              className="btn btn-primary w-md"
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* end form */}
    </React.Fragment>
  )
}

export default connect(null, { setBreadcrumbItems })(AddNewCategory)
